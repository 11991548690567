import { Close, Delete, Done, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

export default function EditTasksModal({
  isOpen,
  handleClose,
  onSave,
  tasks,
  setTasks,
}) {
  const theme = useTheme();

  const [editingTask, setEditingTask] = useState(undefined);
  const [editingMaxValue, setEditingMaxValue] = useState(false);

  const style = {
    borderRadius: "5px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: 24,
    padding: "32px",
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box style={style}>
        <Typography
          component="h2"
          sx={{ color: "white", fontWeight: "500", fontSize: "1.25rem" }}
        >
          Edit tasks
        </Typography>
        <Box
          sx={{
            m: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            color: "white",
          }}
        >
          <List
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.default,
              borderRadius: "5px",
              maxHeight: 300,
              overflow: "auto",
            }}
          >
            {tasks.map((element, index) => {
              return (
                <ListItem
                  sx={{
                    paddingTop:
                      tasks &&
                      tasks.length > index &&
                      !tasks[index].editing &&
                      "17px",
                    paddingBottom:
                      tasks &&
                      tasks.length > index &&
                      !tasks[index].editing &&
                      "17px",
                    paddingLeft: "18px",
                    borderBottom: index < tasks.length - 1 && "1px solid white",
                    display: "flex",
                    alignItems: "center",
                    border:
                      tasks &&
                      tasks.length > index &&
                      tasks[index].editing &&
                      `1px solid ${theme.palette.primary.main}`,
                    borderRadius:
                      tasks &&
                      tasks.length > index &&
                      tasks[index].editing &&
                      "5px",
                  }}
                  id={index}
                  secondaryAction={
                    <>
                      {tasks &&
                      tasks.length > index &&
                      !tasks[index].editing ? (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => {
                              const tmpTasks = [...tasks];
                              for (let k = 0; k < tmpTasks.length; k++) {
                                tmpTasks[k].editing = false;
                              }
                              tmpTasks[index].editing = true;
                              setTasks(tmpTasks);

                              setEditingTask({
                                name: element.name,
                                minValue: element.minValue,
                                maxValue: element.maxValue,
                                id: element.id,
                              });
                              setEditingMaxValue(
                                Boolean(element.maxValue ?? false)
                              );
                            }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            sx={{ marginLeft: "15px", marginRight: "5px" }}
                            onClick={() => {
                              const tmpTasks = [...tasks];
                              tmpTasks.splice(index, 1);

                              setTasks(tmpTasks);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="cancel"
                            onClick={() => {
                              const tmpTasks = [...tasks];
                              for (let k = 0; k < tmpTasks.length; k++) {
                                tmpTasks[k].editing = false;
                              }
                              setTasks(tmpTasks);
                              setEditingTask(undefined);
                            }}
                          >
                            <Close />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="save"
                            sx={{ marginLeft: "15px", marginRight: "5px" }}
                            onClick={() => {
                              const tmpTasks = [...tasks];
                              for (let k = 0; k < tmpTasks.length; k++) {
                                tmpTasks[k].editing = false;
                              }
                              tmpTasks[index].name = editingTask.name;
                              tmpTasks[index].minValue = parseInt(
                                editingTask.minValue
                              );
                              tmpTasks[index].maxValue = editingTask.maxValue
                                ? parseInt(editingTask.maxValue)
                                : null;
                              setEditingTask(undefined);
                              setTasks(tmpTasks);
                            }}
                            disabled={
                              (editingTask.minValue &&
                                editingTask.maxValue &&
                                editingTask.minValue >= editingTask.maxValue) ||
                              (editingTask.minValue &&
                                editingTask.minValue <= 0) ||
                              (editingMaxValue && !editingTask.maxValue)
                            }
                          >
                            <Done />
                          </IconButton>
                        </>
                      )}
                    </>
                  }
                >
                  <ListItemText
                    primary={
                      tasks && tasks.length > index && tasks[index].editing ? (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <TextField
                            label="Name"
                            variant="outlined"
                            sx={{ width: "80%" }}
                            value={editingTask && editingTask.name}
                            onChange={(event) => {
                              const tmpTask = { ...editingTask };
                              tmpTask.name = event.target.value;
                              setEditingTask(tmpTask);
                            }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "10px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <TextField
                              label={editingMaxValue ? "Min Value" : "Value"}
                              sx={{ marginRight: "10px", width: "20%" }}
                              variant="outlined"
                              value={editingTask && editingTask.minValue}
                              type="number"
                              onChange={(event) => {
                                const tmpTask = { ...editingTask };
                                tmpTask.minValue = event.target.value;
                                setEditingTask(tmpTask);
                              }}
                              error={
                                editingTask.minValue &&
                                editingTask.minValue <= 0
                              }
                            />

                            {editingMaxValue && (
                              <TextField
                                label={"Max value"}
                                sx={{ marginRight: "10px", width: "20%" }}
                                value={editingTask && editingTask.maxValue}
                                type="number"
                                onChange={(event) => {
                                  const tmpTask = { ...editingTask };
                                  tmpTask.maxValue = event.target.value;
                                  setEditingTask(tmpTask);
                                }}
                                error={
                                  editingTask.minValue &&
                                  editingTask.maxValue &&
                                  editingTask.minValue >= editingTask.maxValue
                                }
                              />
                            )}
                            <FormControlLabel
                              sx={{
                                color: "white",
                                transitionDuration: "0.5s",
                                width: "150px",
                              }}
                              control={
                                <Switch
                                  checked={editingMaxValue}
                                  onChange={() => {
                                    if (editingMaxValue) {
                                      const tmpEditingTask = { ...editingTask };
                                      tmpEditingTask.maxValue = null;
                                      setEditingTask(tmpEditingTask);
                                    }
                                    setEditingMaxValue(!editingMaxValue);
                                  }}
                                />
                              }
                              label="Max value"
                            />
                          </Box>
                        </Box>
                      ) : (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <>{element.name}</>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "10px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Box sx={{ marginRight: "15px" }}>
                              {element.maxValue ? "Min Value: " : "Value: "}
                              {element.minValue}
                            </Box>
                            {element.maxValue ? (
                              <Box>Max Value: {element.maxValue}</Box>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      )
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box
          sx={{
            marginTop: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ marginLeft: "20px" }}
            onClick={onSave}
            disabled={editingTask}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
