import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";

export default function AddTaskModal({
  isOpen,
  onClose,
  onSave,
  selectedTask,
  selectedPersonAddTask,
  setSelectedPersonAddTask,
  columns,
  selectedValueAddTask,
  setSelectedValueAddTask,
  possibleValuesAddTask,
}) {
  const handleOnSelectedPersonChange = (event) => {
    setSelectedPersonAddTask(event.target.value);
  };

  const handleOnSelectedValueChange = (event) => {
    setSelectedValueAddTask(event.target.value);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          borderRadius: "5px",
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          color: "white",
          bgcolor: "#243447",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Add a task to{' "'}
          {selectedTask && selectedTask.length > 2 && selectedTask[1].value}"
        </Typography>
        <Box
          sx={{
            m: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <FormControl sx={{ display: "flex", width: "20%" }}>
            <InputLabel id="select-person">Who ?</InputLabel>
            <Select
              labelId="select-person"
              label="Select someone"
              value={selectedPersonAddTask}
              onChange={handleOnSelectedPersonChange}
            >
              {columns.slice(3).map((element, index) => {
                return (
                  <MenuItem color="paper" value={element.id}>
                    {element.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Typography>will receive</Typography>
          <FormControl sx={{ display: "flex", width: "20%" }}>
            <InputLabel id="select-value">Value ?</InputLabel>
            <Select
              labelId="select-value"
              label="Select a value"
              value={selectedValueAddTask}
              onChange={handleOnSelectedValueChange}
            >
              {possibleValuesAddTask &&
                possibleValuesAddTask.map((element, index) => {
                  return <MenuItem value={element}>{element}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{ color: "#f0e084", borderColor: "#f0e084" }}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              marginLeft: "20px",
              "&.Mui-disabled": {},
            }}
            variant="contained"
            disabled={
              selectedPersonAddTask === undefined ||
              selectedValueAddTask === undefined
            }
            onClick={onSave}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
