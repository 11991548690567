import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

export default function NewUserModal({
  isOpen,
  handleClose,
  onSave,
  newUserName,
  setNewUserName,
}) {
  const theme = useTheme();

  const style = {
    borderRadius: "5px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: 24,
    padding: "32px",
  };

  const handleChange = (event) => {
    setNewUserName(event.target.value);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box style={style}>
        <Typography
          component="h2"
          sx={{ color: "white", fontWeight: "500", fontSize: "1.25rem" }}
        >
          Create a new user
        </Typography>
        <Box
          sx={{
            m: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <TextField
            label="Name"
            variant="outlined"
            value={newUserName}
            onChange={handleChange}
          />
        </Box>
        <Box
          sx={{
            marginTop: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ marginLeft: "20px" }}
            onClick={onSave}
            disabled={newUserName === undefined}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
