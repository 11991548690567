import { AddTask } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import db from "../../firebase";
import AddTaskModal from "../Modals/AddTaskModal";

const getTypeOfColumn = (index, columns, detailedColumns) => {
  if (index < 3) {
    return columns[index].type;
  }
  return detailedColumns[index - 3].type;
};

const getDetailedFromPersonId = (id, columns) => {
  console.log(id);
  for (let i = 0; i < columns.length; i++) {
    if (columns[i].id === id) {
      return i * 2 - 3;
    }
  }

  return -1;
};

export default function TaskTable({
  columns,
  detailedColumns,
  rows,
  setFetch,
}) {
  const theme = useTheme();

  const [openAddTask, setOpenAddTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [selectedTaskId, setSelectedTaskId] = useState(undefined);
  const [selectedPersonAddTask, setSelectedPersonAddTask] = useState(undefined);
  const [selectedValueAddTask, setSelectedValueAddTask] = useState(undefined);
  const [possibleValuesAddTask, setPossibleValuesAddTask] = useState(undefined);

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  //action modals
  const handleOpenAddTask = (task, taskId) => {
    setSelectedTask(task.row);
    setSelectedTaskId(taskId);
    setPossibleValuesAddTask(
      task.row[2].value.length === 1
        ? task.row[2].value
        : range(task.row[2].value[0], task.row[2].value[1])
    );
    setOpenAddTask(true);
  };

  const getTotal = (index) => {
    let total = 0;
    for (let i = 0; i < rows.length; i++) {
      total += rows[i].row[3 + index].value;
    }

    return total;
  };

  const handleCloseAddTask = () => {
    setSelectedTask(undefined);
    setSelectedTaskId(undefined);
    setSelectedPersonAddTask(undefined);
    setSelectedValueAddTask(undefined);
    setPossibleValuesAddTask(undefined);
    setOpenAddTask(false);
  };

  const handleOnSaveAddTask = async () => {
    const index = getDetailedFromPersonId(selectedPersonAddTask, columns);
    if (index === -1 || index >= selectedTask.length) {
      handleCloseAddTask();
      console.error("Oops, something went wrong...");
    }
    const tmpTask = [...selectedTask];
    tmpTask[index].value.push(selectedValueAddTask);
    tmpTask[index + 1].value = tmpTask[index].value.reduce(
      (partialSum, a) => partialSum + a,
      0
    );

    await setDoc(doc(db, "tables", "DaDEZVuarkXBrhO5TJxZ"), {
      columns: columns,
      detailedColumns: detailedColumns,
      rows: rows,
    }).finally(() => {
      setFetch(true);
      handleCloseAddTask();
    });
  };

  return (
    <>
      <AddTaskModal
        isOpen={openAddTask}
        onClose={handleCloseAddTask}
        onSave={handleOnSaveAddTask}
        selectedTask={selectedTask}
        selectedPersonAddTask={selectedPersonAddTask}
        setSelectedPersonAddTask={setSelectedPersonAddTask}
        columns={columns}
        selectedValueAddTask={selectedValueAddTask}
        setSelectedValueAddTask={setSelectedValueAddTask}
        possibleValuesAddTask={possibleValuesAddTask}
      />
      <TableContainer
        sx={{
          height: "100%",
          margin: "0px 10px 10px 10px",
          color: "white",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    width={column.width ?? "auto"}
                    rowSpan={
                      column.id === "task" ||
                      column.id === "value" ||
                      column.id === "add"
                        ? 2
                        : undefined
                    }
                    colSpan={
                      column.id === "task" ||
                      column.id === "value" ||
                      column.id === "add"
                        ? undefined
                        : 2
                    }
                    sx={{
                      borderTopLeftRadius: index === 0 ? "5px" : "0px",
                      borderTopRightRadius:
                        index === columns.length - 1 ? "5px" : "0px",
                      borderLeft:
                        index !== 0 ? "1px solid rgba(224, 224, 224, 1)" : "",
                      borderColor: "white",
                      minWidth: column.width,
                      width: column.width,
                      color: "#f0e084",
                      fontWeight: "bolder",
                      fontSize: "large",
                      backgroundColor: "#1A2533",
                      padding: "20px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              {detailedColumns.map((column, index) => {
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    width={column.width ?? "auto"}
                    sx={{
                      borderLeft: "1px solid rgba(224, 224, 224, 1)",
                      fontSize: "large",
                      minWidth: column.width,
                      width: column.width,
                      borderColor: "white",
                      color: "#f0e084",
                      fontWeight: "bold",
                      backgroundColor: "#1A2533",
                      padding: "15px",
                    }}
                  >
                    {column.label === "Total" ? (
                      <>
                        {column.label}: {getTotal(index)}
                      </>
                    ) : (
                      <>{column.label}</>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  hover={true}
                  component="tr"
                  sx={{
                    "&:last-child td, &:last-child th": { borderBottom: 0 },
                    color: "white",
                    backgroundColor: index % 2 === 0 ? "#243447" : "#2B3D54",
                    "&.MuiTableRow-hover:hover": {
                      backgroundColor: "#425F82",
                    },
                  }}
                >
                  {row.row.map((item, indexItem) => {
                    const type = getTypeOfColumn(
                      indexItem,
                      columns,
                      detailedColumns
                    );
                    return (
                      <TableCell
                        key={indexItem}
                        component="td"
                        scope="row"
                        sx={{
                          color: "white",
                          borderColor: "white",
                          borderLeft:
                            indexItem !== 0
                              ? "1px solid rgba(224, 224, 224, 1)"
                              : "",
                        }}
                      >
                        {type === "button" ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => handleOpenAddTask(row, index)}
                            >
                              <AddTask style={{ color: "#f0e084" }} />
                            </IconButton>
                          </div>
                        ) : type === "range" ? (
                          <>
                            {item.value.length === 2 ? (
                              <>
                                {item.value[0]}-{item.value[1]}
                              </>
                            ) : (
                              <>{item.value[0]}</>
                            )}
                          </>
                        ) : type === "number-list" ? (
                          <>
                            {item.value && item.value.length > 0
                              ? item.value.join(", ")
                              : "0"}
                          </>
                        ) : (
                          <>{item.value}</>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
