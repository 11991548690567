// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBm5xbpp7ymfdm2WffdRpWBryFGWSDTZzk",
  authDomain: "home-management-6e186.firebaseapp.com",
  projectId: "home-management-6e186",
  storageBucket: "home-management-6e186.appspot.com",
  messagingSenderId: "217377997146",
  appId: "1:217377997146:web:7628e9b6131db2394c3363",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default getFirestore();
export { auth };
