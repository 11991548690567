import {
  Edit,
  FormatListBulleted,
  KeyboardArrowDown,
  Person,
  PlaylistAdd,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import EditTasksModal from "../Modals/EditTasksModal";
import EditUsersModal from "../Modals/EditUsersModal";
import NewTaskModal from "../Modals/NewTaskModal";
import NewUserModal from "../Modals/NewUserModal";
import TaskTable from "../TaskTable";
import db from "./../../firebase";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    //minWidth: 180,
    width: "fit-content",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MainTable({
  columns,
  detailedColumns,
  rows,
  status,
  setFetch,
}) {
  const theme = useTheme();

  const [newTaskModalOpen, setNewTaskModalOpen] = useState(false);
  const [editTasksModalOpen, setEditTasksModalOpen] = useState(false);
  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const [editUsersModalOpen, setEditUsersModalOpen] = useState(false);

  const [tasksAnchorEl, setTasksAnchorEl] = useState(null);
  const [usersAnchorEl, setUsersAnchorEl] = useState(null);

  const isTasksMenuExtended = Boolean(tasksAnchorEl);
  const isUsersMenuExtended = Boolean(usersAnchorEl);

  const handleTasksClick = (event) => {
    setTasksAnchorEl(event.currentTarget);
    setUsersAnchorEl(null);
  };

  const handleUsersClick = (event) => {
    setUsersAnchorEl(event.currentTarget);
    setTasksAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setTasksAnchorEl(null);
    setUsersAnchorEl(null);
  };

  const [newTaskName, setNewTaskName] = useState(undefined);
  const [newTaskValue, setNewTaskValue] = useState(undefined);
  const createNewTask = async () => {
    if (newTaskName && newTaskValue) {
      const toAdd = {
        row: [
          { value: "Add" },
          { value: newTaskName },
          { value: newTaskValue },
        ],
      };

      for (let i = 0; i < detailedColumns.length; i += 2) {
        toAdd.row.push({ value: [] }, { value: 0 });
      }

      rows.push(toAdd);
    }
    await setDoc(doc(db, "tables", "DaDEZVuarkXBrhO5TJxZ"), {
      columns: columns,
      detailedColumns: detailedColumns,
      rows: rows,
    }).finally(() => {
      setFetch(true);
      handleNewTaskModalClose();
    });
  };

  const handleNewTaskModalOpen = () => {
    setNewTaskModalOpen(true);
    handleCloseMenu();
  };

  const handleNewTaskModalClose = () => {
    setNewTaskModalOpen(false);
    setNewTaskName(undefined);
    setNewTaskValue(undefined);
  };

  const handleEditTasksModalOpen = () => {
    const tmpTasks = [];
    for (let i = 0; i < rows.length; i++) {
      tmpTasks.push({
        name: rows[i].row[1].value,
        id: i,
        editing: false,
        minValue: rows[i].row[2].value[0],
        maxValue: rows[i].row[2].value[1] ?? null,
      });
    }
    setEditTasks(tmpTasks);
    setEditTasksModalOpen(true);
    handleCloseMenu();
  };

  const handleEditTasksModalClose = () => {
    setEditTasks([]);
    setEditTasksModalOpen(false);
  };

  const [editTasks, setEditTasks] = useState([]);
  const onSaveEditTask = async () => {
    console.log(editTasks);
    let foundTask = null;
    const toDelete = [];
    for (let i = 0; i < rows.length; i++) {
      foundTask = editTasks.find((element) => element.id === i);
      if (foundTask) {
        rows[i].row[1].value = foundTask.name;
        rows[i].row[2].value = foundTask.maxValue
          ? [foundTask.minValue, foundTask.maxValue]
          : [foundTask.minValue];
      } else {
        toDelete.push(i);
      }
    }

    for (let i = toDelete.length - 1; i >= 0; i--) {
      rows.splice(toDelete, 1);
    }

    await setDoc(doc(db, "tables", "DaDEZVuarkXBrhO5TJxZ"), {
      columns: columns,
      detailedColumns: detailedColumns,
      rows: rows,
    }).finally(() => {
      setFetch(true);
      handleEditTasksModalClose();
    });
  };

  const handleNewUserModalOpen = () => {
    setNewUserModalOpen(true);
    handleCloseMenu();
  };

  const handleNewUserModalClose = () => {
    setNewUserName(undefined);
    setNewUserModalOpen(false);
  };

  const [newUserName, setNewUserName] = useState(undefined);
  const createNewUser = async () => {
    if (newUserName) {
      const idUser = newUserName.toString().toLowerCase().replace(" ", "-");
      columns.push({
        id: idUser,
        label: newUserName.toString(),
        align: "center",
        type: "text",
        width: "23vw",
      });
      detailedColumns.push(
        {
          id: `${idUser}-details`,
          label: "Details",
          align: "center",
          type: "number-list",
          width: "70%",
          //width: `${0.35 * 70}%`,
        },
        {
          id: `${idUser}-total`,
          label: "Total",
          align: "center",
          type: "text",
          width: "30%",
          //width: `${0.35 * 30}%`,
        }
      );
      for (let i = 0; i < rows.length; i++) {
        rows[i].row.push({ value: [] }, { value: 0 });
      }
    } else {
      console.error("Failed to create the user");
    }

    await setDoc(doc(db, "tables", "DaDEZVuarkXBrhO5TJxZ"), {
      columns: columns,
      detailedColumns: detailedColumns,
      rows: rows,
    }).finally(() => {
      setFetch(true);
      setNewUserName(undefined);
      handleNewUserModalClose();
    });
  };

  const [editUsers, setEditUsers] = useState([]);
  const onSaveEditUser = async () => {
    let foundUser = null;
    const toDelete = [];
    for (let i = 3; i < columns.length; i++) {
      foundUser = editUsers.find((element) => element.id === i - 3);
      if (foundUser) {
        columns[i].label = foundUser.name;
      } else {
        toDelete.push(i);
      }
    }

    for (let i = toDelete.length - 1; i >= 0; i--) {
      for (let j = 0; j < rows.length; j++) {
        rows[j].row.splice((toDelete[i] - 3) * 2 + 3, 1);
        rows[j].row.splice((toDelete[i] - 3) * 2 + 3, 1);
      }
      detailedColumns.splice((toDelete[i] - 3) * 2, 1);
      detailedColumns.splice((toDelete[i] - 3) * 2, 1);
      columns.splice(toDelete[i], 1);
    }

    await setDoc(doc(db, "tables", "DaDEZVuarkXBrhO5TJxZ"), {
      columns: columns,
      detailedColumns: detailedColumns,
      rows: rows,
    }).finally(() => {
      setFetch(true);
      handleEditUsersModalClose();
    });
  };

  const handleEditUsersModalOpen = () => {
    const tmpUsers = [];
    for (let i = 3; i < columns.length; i++) {
      tmpUsers.push({ name: columns[i].label, id: i - 3, editing: false });
    }
    setEditUsers(tmpUsers);
    setEditUsersModalOpen(true);
    handleCloseMenu();
  };

  const handleEditUsersModalClose = () => {
    setEditUsers([]);
    setEditUsersModalOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        height: "85%",
        width: "95%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <NewUserModal
        isOpen={newUserModalOpen}
        handleClose={handleNewUserModalClose}
        onSave={createNewUser}
        newUserName={newUserName}
        setNewUserName={setNewUserName}
      />
      <NewTaskModal
        isOpen={newTaskModalOpen}
        handleClose={handleNewTaskModalClose}
        onSave={createNewTask}
        newTaskName={newTaskName}
        setNewTaskName={setNewTaskName}
        newTaskValue={newTaskValue}
        setNewTaskValue={setNewTaskValue}
      />
      <EditUsersModal
        isOpen={editUsersModalOpen}
        handleClose={handleEditUsersModalClose}
        onSave={onSaveEditUser}
        users={editUsers}
        setUsers={setEditUsers}
      />
      <EditTasksModal
        isOpen={editTasksModalOpen}
        handleClose={handleEditTasksModalClose}
        onSave={onSaveEditTask}
        tasks={editTasks}
        setTasks={setEditTasks}
      />
      <Box
        className="tableContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100% - 40px)",
          width: "calc(100% - 40px)",
        }}
      >
        {status === "loading" ? (
          <>
            <CircularProgress />
          </>
        ) : (
          <>
            <Box
              sx={{
                width: "100%",
                marginBottom: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  margin: "0px",
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                Main table
              </Typography>
              <Box
                sx={{
                  width: "fit-content",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ marginRight: "20px" }}
                  endIcon={
                    isTasksMenuExtended ? (
                      <KeyboardArrowDown />
                    ) : (
                      <FormatListBulleted />
                    )
                  }
                  id="tasks-menu-button"
                  aria-controls={
                    isTasksMenuExtended ? "demo-customize-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={isTasksMenuExtended ? "true" : undefined}
                  onClick={handleTasksClick}
                  disableElevation
                >
                  Tasks
                </Button>
                <StyledMenu
                  id="tasks-menu-button"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={tasksAnchorEl}
                  open={isTasksMenuExtended}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={handleEditTasksModalOpen} disableRipple>
                    <Edit />
                    Edit
                  </MenuItem>
                  <MenuItem onClick={handleNewTaskModalOpen} disableRipple>
                    <PlaylistAdd />
                    New
                  </MenuItem>
                </StyledMenu>
                <Button
                  variant="contained"
                  endIcon={
                    isUsersMenuExtended ? <KeyboardArrowDown /> : <Person />
                  }
                  id="users-menu-button"
                  aria-controls={
                    isUsersMenuExtended ? "demo-customize-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={isUsersMenuExtended ? "true" : undefined}
                  onClick={handleUsersClick}
                  disableElevation
                >
                  Users
                </Button>
                <StyledMenu
                  id="users-menu-button"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={usersAnchorEl}
                  open={isUsersMenuExtended}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={handleEditUsersModalOpen} disableRipple>
                    <Edit />
                    Edit
                  </MenuItem>
                  <MenuItem onClick={handleNewUserModalOpen} disableRipple>
                    <PlaylistAdd />
                    New
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Box>
            <TaskTable
              columns={columns}
              detailedColumns={detailedColumns}
              rows={rows}
              setFetch={setFetch}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
