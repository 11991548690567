import { Close, Delete, Done, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

export default function EditUsersModal({
  isOpen,
  handleClose,
  onSave,
  users,
  setUsers,
}) {
  const theme = useTheme();

  const style = {
    borderRadius: "5px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: 24,
    padding: "32px",
  };

  const [editingUser, setEditingUser] = useState(undefined);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
    >
      <Box style={style}>
        <Typography
          component="h2"
          sx={{ color: "white", fontWeight: "500", fontSize: "1.25rem" }}
        >
          Edit users
        </Typography>
        <Box
          sx={{
            m: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            color: "white",
          }}
        >
          <List
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.default,
              borderRadius: "5px",
              maxHeight: 300,
              overflow: "auto",
            }}
          >
            {users.map((element, index) => {
              return (
                <ListItem
                  sx={{
                    paddingTop:
                      users &&
                      users.length > index &&
                      !users[index].editing &&
                      "17px",
                    paddingBottom:
                      users &&
                      users.length > index &&
                      !users[index].editing &&
                      "17px",
                    paddingLeft: "18px",
                    borderBottom: index < users.length - 1 && "1px solid white",
                    display: "flex",
                    alignItems: "center",
                    border:
                      users &&
                      users.length > index &&
                      users[index].editing &&
                      `1px solid ${theme.palette.primary.main}`,
                    borderRadius:
                      users &&
                      users.length > index &&
                      users[index].editing &&
                      "5px",
                  }}
                  id={index}
                  secondaryAction={
                    <>
                      {users &&
                      users.length > index &&
                      !users[index].editing ? (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => {
                              const tmpUsers = [...users];
                              for (let k = 0; k < tmpUsers.length; k++) {
                                tmpUsers[k].editing = false;
                              }
                              tmpUsers[index].editing = true;
                              setUsers(tmpUsers);
                              setEditingUser({
                                name: element.name,
                                id: element.id,
                              });
                            }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            sx={{ marginLeft: "15px", marginRight: "5px" }}
                            onClick={() => {
                              const tmpUsers = [...users];
                              tmpUsers.splice(index, 1);
                              /*for (let k = 0; k < tmpUsersProps.length; k++) {
                                if (tmpUsersProps[k].id === ) {
                                  tmpUsersProps.splice(index, 1);
                                  break;
                                }
                              }*/

                              setUsers(tmpUsers);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="cancel"
                            onClick={() => {
                              const tmpUsers = [...users];
                              for (let k = 0; k < tmpUsers.length; k++) {
                                tmpUsers[k].editing = false;
                              }
                              setUsers(tmpUsers);
                              setEditingUser(undefined);
                            }}
                          >
                            <Close />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="save"
                            sx={{ marginLeft: "15px", marginRight: "5px" }}
                            onClick={() => {
                              const tmpUsers = [...users];
                              for (let k = 0; k < tmpUsers.length; k++) {
                                tmpUsers[k].editing = false;
                              }
                              setEditingUser(undefined);
                              tmpUsers[index].name = editingUser.name;
                              setUsers(tmpUsers);
                            }}
                          >
                            <Done />
                          </IconButton>
                        </>
                      )}
                    </>
                  }
                >
                  <ListItemText
                    primary={
                      users && users.length > index && users[index].editing ? (
                        <>
                          <TextField
                            label="Name"
                            variant="outlined"
                            value={editingUser && editingUser.name}
                            onChange={(event) => {
                              const tmpUser = { ...editingUser };
                              tmpUser.name = event.target.value;
                              setEditingUser(tmpUser);
                            }}
                          />
                        </>
                      ) : (
                        <>{element.name}</>
                      )
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box
          sx={{
            marginTop: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ marginLeft: "20px" }}
            onClick={() => {
              onSave();
            }}
            disabled={users && users.find((element) => element.editing)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
