import { Send } from "@mui/icons-material";
import {
  Box,
  Button,
  createTheme,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { useQuery } from "react-query";
import "./App.scss";
import Header from "./components/Header";
import MainTable from "./components/MainTable";
import db, { auth } from "./firebase";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "rgb(240, 224, 132)",
    },
    background: {
      default: "#1a2533",
      paper: "#243447",
    },
  },
});

function App() {
  /*const columns = [
    { id: "add", label: "", type: "button", width: "5vw", align: "center" },
    { id: "task", label: "Task", type: "text", width: "20vw", align: "center" },
    {
      id: "value",
      label: "Value",
      type: "range",
      //width: "500vw",
      width: "5vw",
      align: "center",
    },
    {
      id: "person1",
      label: "Person 1",
      align: "center",
      type: "text",
      width: "23vw",
    },
    {
      id: "person2",
      label: "Person 2",
      align: "center",
      type: "text",
      width: "23vw",
    },
  ];

  const detailedColumns = [
    {
      id: "person1-details",
      label: "Details",
      align: "center",
      type: "number-list",
      width: "70%",
      //width: `${0.35 * 70}%`,
    },
    {
      id: "person1-total",
      label: "Total",
      align: "center",
      type: "text",
      width: "30%",
      //width: `${0.35 * 30}%`,
    },
    {
      id: "person2-details",
      label: "Details",
      align: "center",
      type: "number-list",
      width: "70%",
      //width: `${0.35 * 70}%`,
    },
    {
      id: "person2-total",
      label: "Total",
      align: "center",
      type: "text",
      width: "30%",
      //width: `${0.35 * 30}%`,
    },
  ];

  const rows = [
    [
      { value: "Add" },
      { value: "Buy some milk" },
      { value: [2, 5] },
      { value: [2] },
      { value: 2 },
      { value: [2, 2, 2] },
      { value: 6 },
    ],
    [
      { value: "Add" },
      { value: "Clean windows" },
      { value: [2, 3] },
      { value: [2] },
      { value: 2 },
      { value: [3] },
      { value: 3 },
    ],
    [
      { value: "Add" },
      { value: "Put new batteries" },
      { value: [2] },
      { value: [] },
      { value: 0 },
      { value: [2] },
      { value: 2 },
    ],
  ];*/

  const [columns, setColumns] = useState([]);
  const [detailedColumns, setDetailedColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [fetch, setFetch] = useState(true);

  const { status } = useQuery(
    ["table", columns, detailedColumns, rows, fetch],
    () => getDoc(doc(db, "tables", "DaDEZVuarkXBrhO5TJxZ")),
    {
      enabled: Boolean(columns && detailedColumns && rows && fetch),
      onSuccess: (data) => {
        const result = data.data();
        setColumns(result.columns);
        setDetailedColumns(result.detailedColumns);
        setRows(result.rows);
        setFetch(false);
      },
      onError: (error) => {
        console.error(error);
        setFetch(false);
      },
    }
  );

  /*useEffect(() => {
    getDoc(doc(db, "tables", "DaDEZVuarkXBrhO5TJxZ")).then((data) =>
      console.log(data.data())
    );
    let tablesRef = db.collection("tables");
    let query = tablesRef
      .where(
        firebase.firestore.FieldPath.documentId(),
        "==",
        "DaDEZVuarkXBrhO5TJxZ"
      )
      .get()
      .then((data) => console.log(data));
    onSnapshot(collection(db, "tables"), (snapshot) => {
      console.log(snapshot.docs.map((doc) => doc.data()));
    });
  }, []);*/

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);

  /*onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });*/

  const login = () => {
    signInWithEmailAndPassword(auth, loginEmail, loginPassword)
      .then((data) => {
        setError(false);
        setUser(data.user);
      })
      .catch((error) => {
        console.error(error.message);
        setError(true);
      })
      .finally(() => {
        setLoginEmail("");
        setLoginPassword("");
      });
  };

  const logout = async () => {
    const user = await signOut(auth);
    setUser(user);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="app">
        <Header logout={logout} displayLogout={user} />
        <div className="body">
          {user ? (
            <MainTable
              columns={columns}
              detailedColumns={detailedColumns}
              rows={rows}
              status={status}
              setFetch={setFetch}
            />
          ) : (
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: "8px",
                height: "85%",
                width: "95%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                component="h3"
                sx={{ fontSize: theme.typography.h5.fontSize }}
              >
                Please login before continue
              </Typography>
              <TextField
                sx={{ marginTop: "40px" }}
                value={loginEmail}
                label="Email"
                name="email"
                onChange={(event) => setLoginEmail(event.target.value)}
                error={error}
              />
              <TextField
                sx={{ marginTop: "20px" }}
                label="password"
                type="password"
                name="password"
                value={loginPassword}
                onChange={(event) => setLoginPassword(event.target.value)}
                error={error}
              />
              <Button
                variant="contained"
                endIcon={<Send />}
                sx={{ marginTop: "30px" }}
                disabled={loginEmail.length === 0 || loginPassword.length === 0}
                onClick={login}
              >
                Login
              </Button>
            </Box>
          )}
        </div>
      </Box>
    </ThemeProvider>
  );
}

export default App;
