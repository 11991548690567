import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

export default function NewTaskModal({
  isOpen,
  handleClose,
  onSave,
  newTaskName,
  setNewTaskName,
  newTaskValue,
  setNewTaskValue,
}) {
  const theme = useTheme();
  const [maxValue, setMaxValue] = useState(false);
  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);

  const style = {
    borderRadius: "5px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: 24,
    padding: "32px",
  };

  const handleSetMaxValue = () => {
    setMaxValue(!maxValue);
    if (!maxValue && newTaskValue && newTaskValue[1]) {
      setNewTaskValue([newTaskValue[0]]);
    }
  };

  const handleChangeName = (event) => {
    setNewTaskName(event.target.value);
  };

  const handleChangeValueMin = (event) => {
    const value = parseInt(event.target.value);
    if (value && value > 0) {
      setMin(value);
      if (maxValue && newTaskValue && newTaskValue[1]) {
        setNewTaskValue([value, newTaskValue[1]]);
      } else {
        setNewTaskValue([value]);
      }
    } else {
      console.error("Cant set the value");
    }
  };

  const handleChangeValueMax = (event) => {
    const value = parseInt(event.target.value);
    if (value && value > 0) {
      setMax(value);
      if (maxValue && newTaskValue && newTaskValue[0]) {
        setNewTaskValue([newTaskValue[0], value]);
      } else {
        setNewTaskValue([0, value]);
      }
    } else {
      console.error("Cant set the value");
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box style={style}>
        <Typography
          component="h2"
          sx={{ color: "white", fontWeight: "500", fontSize: "1.25rem" }}
        >
          Create a new task
        </Typography>
        <Box
          sx={{
            m: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "flex-start",
          }}
        >
          <TextField
            label="Name"
            variant="outlined"
            maxWidth
            value={newTaskName}
            onChange={handleChangeName}
          />
          <Box
            sx={{
              marginTop: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              transitionDuration: "0.5s",
            }}
          >
            <TextField
              label={!maxValue ? "Value" : "Min Value"}
              sx={{ marginRight: "15px" }}
              variant="outlined"
              value={min}
              onChange={handleChangeValueMin}
              type="number"
            />
            {maxValue && (
              <TextField
                sx={{ transitionDuration: "0.5s", marginRight: "15px" }}
                label="Max value"
                variant="outlined"
                value={max}
                onChange={handleChangeValueMax}
                disabled={!maxValue}
                type="number"
                error={min && max && min >= max}
              />
            )}
            <FormControlLabel
              sx={{
                color: "white",
                transitionDuration: "0.5s",
                width: "200px",
              }}
              control={
                <Switch checked={maxValue} onChange={handleSetMaxValue} />
              }
              label="Max value"
            />
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ marginLeft: "20px" }}
            onClick={() => {
              setMin(undefined);
              setMax(undefined);
              onSave();
            }}
            disabled={
              newTaskName === undefined ||
              min === undefined ||
              (max === undefined && maxValue) ||
              (maxValue && min && max && min >= max)
            }
          >
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
