import { Button, Typography } from "@mui/material";

export default function Header({ logout, displayLogout }) {
  return (
    <header
      style={{
        color: "white",
        backgroundColor: "#243447",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "none",
        height: "8vh",
        position: "sticky",
      }}
    >
      <Typography sx={{ marginLeft: "100px" }}>
        <h2>Home Management</h2>
      </Typography>
      {displayLogout && (
        <Button
          sx={{ marginRight: "50px" }}
          onClick={logout}
          variant="contained"
        >
          Logout
        </Button>
      )}
    </header>
  );
}
